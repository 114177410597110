function mobileCheck () {
    return window.matchMedia('(max-width:768px)').matches;
  }

function setButton () {
   const isMobile = mobileCheck(); 
   if(isMobile) {
    const container = document.getElementsByClassName('container').item(0);
    const button = document.createElement('a');
    button.appendChild(document.createTextNode('Get in touch'));
    button.setAttribute('id','button-mobile')
    button.setAttribute('class','button-mobile');
    button.setAttribute('href','#form');
    container.appendChild(button);
   }
}
    if(mobileCheck() )
        window.addEventListener('scroll',function(){
            const targetElement = document.getElementById('form');
            const button = document.getElementById('button-mobile');
            const targetPosition = targetElement.getBoundingClientRect();
            const isMobile = mobileCheck(); 
            targetPosition.top<this.window.innerHeight && targetPosition.bottom>=0 ? button.setAttribute('class','button-mobile-hidden') : button.setAttribute('class','button-mobile')
        })
document.addEventListener('resize',setButton());
